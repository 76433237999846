import React, { Component } from 'react';
import DateTimePicker from 'react-datetime-picker';
import './ExpiredCalendarEntry.css';

// instruction https://www.npmjs.com/package/react-datetime-picker

class ExpiredCalendarEntry extends Component {

  constructor(props) {
    super(props);
    const miniStartDate = new Date();
    this.state = {
      now: new Date(),
      startDate: new Date(),
      endDate:  new Date(miniStartDate.setDate(miniStartDate.getDate() + 6))
    };
    this.props.onSelectExpirationDate(this.state.startDate, this.state.endDate);
  }

  onChangeStartDate = (date) => {
    this._setStartDate(date).then(() => {
      this.props.onSelectExpirationDate(this.state.startDate, this.state.endDate);
    }
    );
  }

  _setStartDate = async (date) => {
    this.setState({ startDate: date });
  }

  onChangeEndDate = (date) => {
    this._setEndDate(date).then(() => {
      this.props.onSelectExpirationDate(this.state.startDate, this.state.endDate);
    }
    );
  }

  _setEndDate = async (date) => {
    this.setState({ endDate: date });
  }

  render() {
    return (
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-auto ">
            Start date time:
            <DateTimePicker
              onChange={this.onChangeStartDate}
              locale="fr-FR"
              value={this.state.startDate}
              minDate={this.state.now}
              maxDetail="minute"
              name="startDate"
              className="mt1 ml1"
              showLeadingZeros={true}
            />
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-md-auto ">
            End date time:
            <DateTimePicker
              onChange={this.onChangeEndDate}
              locale="fr-FR"
              value={this.state.endDate}
              minDate={this.state.startDate}
              maxDetail="minute"
              name="endDate"
              className="mt1 ml1"
              showLeadingZeros={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ExpiredCalendarEntry;