import React from 'react';
import SMSForm from '../smscompose/SMSCompose';
import SMSRead from '../smsread/SMSRead';
import SMSUserGuide from '../smsuserguide/SMSUserGuide';
import { Tabs, Tab } from 'react-bootstrap-tabs';

class SMSNavbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            identificationKey: window.location.search.substring(1)
        }
    };

    render() {
        return (
            <div className="container">
                <Tabs>
                    <Tab label="Read Message">
                        <div className="mtb2">
                            <SMSRead identificationKey={this.state.identificationKey}/>
                        </div>
                    </Tab>
                    <Tab label="Send Message">
                        <div className="mtb2">
                            <SMSForm />
                        </div>
                    </Tab>
                    <Tab label="User guide">
                        <div className="mtb2">
                            <SMSUserGuide />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

export default SMSNavbar;