import React from 'react';
import ExpiredCalendarTest from '../expiredCalendar/ExpiredCalendarEntry';
import { MDBCol, MDBInput, MDBBtn, MDBCard, MDBCardBody } from "mdbreact";
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './SMSCompose.css';

class SMSForm extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      serverUrl: "https://api.digi-lion.com:5050/securemessagemanager/sendmessage",
      intervalReloadPage: 1000 * 60 * 60,
      to: null,
      hint: null,
      message: null,
      val: null,
      password: null,
      secondpassword: null,
      expiration: 'autodestruction',
      scription: 'The content of a textarea goes in the value attribute',
      errormessage: '',
      checkedOptions: false,
      autoDestructioned: null,
      timedMessage: null,
      neverExpireMessage: null,
      beginingDate: null,
      expirationDate: null,
      messageIdentifiction: null,
      serverErrorMessage: null,
      showForm: true,
      showError: false,
      showIdentificationMessage: false
    }
  };

  handlerExpiration = (event) => {
    this.setState({ expiration: event.target.value });
  }

  handleCheckOptions = (event) => {
    if(this.state.checkedOptions === true){
      this.initOptionValues();
    }
    this.setState({ checkedOptions: !this.state.checkedOptions })
  }

  initOptionValues = () => {
    this.setState({password: null});
    this.setState({secondPassword : null});
    this.setState({to : null});
    this.setState({hint : null});
  }

  passwordHandler = (event) => {
    this.setState({ password: event.target.value })
  }

  secondPasswordHandler = (event) => {
    this.setState({ secondpassword: event.target.value })
  }

  handleHint = (event) => {
    this.setState({ hint: event.target.value })
  }

  handleDestination = (event) => {
    this.setState({ to: event.target.value })
  }

  handleExpirationDate = (dateDebut, dateFin) => {
    this.setState({ beginingDate: dateDebut });
    this.setState({ expirationDate: dateFin });
  }

  handleSubmit = (event) => {
    this.handleExpirationDate();
    let data = null;
    this._buildExpirationMessage().then(() => {
      data = {
        message: this.state.message,
        autoDestructioned: this.state.autoDestructioned,
        neverExpireMessage: this.state.neverExpireMessage,
        to: this.state.to,
        hint: this.state.hint,
        password: this.state.password !== '' ? this.state.password : null,
        secondPassword: (this.state.password !== '' && this.state.secondpassword !== '') ? this.state.secondpassword : null,
        timedMessage: this.state.timedMessage
      }
    }).then(() => {
      this.getDataAxios(data);
    });

  }

  _buildExpirationMessage = async () => {

    let beginDate = null;
    let endDate = null;
    if (this.state.expiration === "autodestruction") {
      this.setState({ autoDestructioned: true });
    } else if (this.state.expiration === "never") {
      this.setState({ neverExpireMessage: true });
    } else if (this.state.expiration === "timed") {
      beginDate = this.state.beginingDate;
      endDate = this.state.expirationDate;
      this._buildValueTimedMessage(beginDate, endDate).then((res) => {
        this.setState({ timedMessage: res });
      })
    }
  }

  _buildValueTimedMessage = async (beginDate, endDate) => {
    return {
      startDate: new Date(beginDate.setMinutes(beginDate.getMinutes() - beginDate.getTimezoneOffset())),
      endDate: new Date(endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset()))
    }
  }

  handleChangeMessage = (event) => {
    this.setState({ message: event.target.value })
  }

  async getDataAxios(data) {
    await axios.post(
      this.state.serverUrl,
      data,
      { headers: { 'Content-Type': 'application/json' } }
    ).then(res => {
      this.setState({ messageIdentifiction: res.data.messageIdentification });
      this.setState({ showForm: false });
      this.setState({ showIdentificationMessage: true });
      setInterval(this.refreshPage, this.state.intervalReloadPage);
    }).catch(error => {
      this.setState({ showError: true });
      this.setState({ showForm: false });
      if (error.response !== undefined) {
        this.setState({ serverErrorMessage: error.response.data });
      } else {
        this.setState({ serverErrorMessage: "The Secure Message Sender server is temporarily unavailable because messages could be insecure. Services will be back when you can send messages without fear." });
      }
      setInterval(this.refreshPage, 10000);
    }
    );
  }

  refreshPage() {
    window.location.reload(false);
  }

  render() {
    return (
      <div>
        <div className="container" hidden={!this.state.showIdentificationMessage}>
          <p>Identification of your message : </p>
          {this.state.messageIdentifiction}
        </div>
        <div className="container" hidden={!this.state.showError}>
          <p>Error message :</p>
          <div className="errorMessage">{this.state.serverErrorMessage}</div>
          <p>For confidentiality reasons, your transaction is deleted</p>
        </div>
        <div className="container" hidden={!this.state.showForm}>
          <div className="row justify-content-md-center">
            <MDBCol md="12">
              <MDBCard>
                <MDBCardBody>
                  <div>
                    <p className="h4 text-center py-4">Compose message</p>
                    <div className="grey-text">
                      <MDBInput type="textarea" rows="2" label="Your message" icon="pencil-alt" name={this.state.message} onChange={this.handleChangeMessage} />
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <label className="input-group-text" htmlFor="inputGroupSelect01">Expirations :</label>
                        </div>
                        <select className="custom-select" id="inputGroupSelect01" onChange={this.handlerExpiration}>
                          <option value="autodestruction">Auto-destruction</option>
                          <option value="timed">Timed</option>
                          <option value="never">Never expire</option>
                        </select>
                      </div>
                      <div>
                        {this.state.expiration === 'timed' ? <ExpiredCalendarTest onSelectExpirationDate={this.handleExpirationDate} /> : null}

                      </div>
                      <div className="custom-control custom-checkbox mt1">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" defaultChecked={this.state.checkedOptions} onChange={this.handleCheckOptions} />
                        <label className="custom-control-label" htmlFor="customCheck1">More options</label>
                      </div>
                      {this.state.checkedOptions ?
                        <div>
                          <MDBInput
                            label="Password"
                            icon="unlock-alt"
                            group
                            type="password"
                            name="password"
                            validate
                            error="wrong"
                            success="right"
                            onChange={this.passwordHandler}
                          />
                        </div>
                        : null}
                      {this.state.checkedOptions && this.state.password !== null && this.state.password !== '' ?
                        <div>
                          <MDBInput
                            label="Second password"
                            icon="unlock-alt"
                            group
                            type="password"
                            name="secondpassword"
                            validate
                            error="wrong"
                            success="right"
                            onChange={this.secondPasswordHandler}
                          />
                        </div> : null}
                      {this.state.checkedOptions ?
                        <div>
                          <MDBInput
                            label="To (email address)"
                            icon="envelope"
                            group
                            type="text"
                            name='to'
                            validate
                            error="wrong"
                            success="right"
                            onChange={this.handleDestination}
                          />
                        </div>
                        : null}
                      {this.state.checkedOptions ?
                        <div>
                          <MDBInput
                            label="Hint"
                            icon="user-secret"
                            group
                            type="text"
                            name='hint'
                            validate
                            error="wrong"
                            success="right"
                            onChange={this.handleHint}
                          />
                        </div>
                        : null}
                    </div>
                    <div className="text-center py-4 mt-3">
                      <MDBBtn color="elegant" type="submit" onClick={this.handleSubmit}>Send Message</MDBBtn>
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </div>
        </div>
      </div>
    )
  }
}

export default SMSForm;