import React from 'react';
import './SMSUserGuide.css';

class SMSUserGuide extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    };

    render() {
        return (
            <div className="container">
                <div>

                    <h3>What is the purpose of the Secure Message Sender?</h3>
                    <div className="text-content">
                        <p className="important-block">Secure Message Sender allows you to send messages in a completely secret, anonymous, untraceable and fully encrypted way.</p>
                        <p>The encryption-decryption process uses an encryption algorithm concatenated to a strong identifier defined by a powerful algorithm, concatenated to your encrypted password (if you have defined one).
                            This makes it impossible to decrypt your message without the password you set.
                        Decryption is simply impossible by anyone (Secure Message Sender administrators or even in case of database theft).</p>
                        <p><span className="important-block">After deleting a message, there is no trace of the message left.</span> It will be impossible to know that your message ever existed.
                            The deletion is done from the database.
                            <span className="important-block">No backup of your messages is made. No information is deposited in a cloud.</span></p>
                    </div>
                    <hr />
                    <h3>How do I use Secure Message Sender?</h3>
                    <div className="text-content">
                            <ul>
                                <li><span className="important-block">Compose your message.</span></li>
                                <li><span className="important-block">Choose the type of expiration of your message:</span></li>
                                <ul>
                                    <li>"Auto Destruction" (default value): the message will be automatically destroyed after consultation. This operation is irreversible.</li>
                                    <li>"Timed": the message has a period of validity, it is only consumable during its period of validity. Once this period has expired, it will be automatically deleted from the database. This deletion is irreversible.</li>
                                    <li>"Never expire": Your message has no lifetime, it is also saved securely but will never be deleted.</li>
                                </ul>
                                <li><span className="important-block">Optionally, "password".</span> You can choose a password.<br />
                                    This password is also stored in encrypted form.<br />
                                    You have no possibility to change it. Be careful when entering your password. We do not ask you to re-enter your password for confirmation.<br />
                                    Communicate this password via another communication channel. Do not attach your password to the recipient of your message through the same channel. People who stumble upon your message may use it to decrypt your message.
                                </li>
                                <li><span className="important-block">Optionally, "second password".</span> You can choose a second password.<br />
                                    This password is also stored in encrypted form.<br />
                                    You have no possibility to change it. Be careful when entering your password. We do not ask you to re-enter your password for confirmation.<br />
                                    Communicate this password via another communication channel. Do not attach your password to the recipient of your message through the same channel. People who stumble upon your message may use it to decrypt your message.<br />
                                    The advantage of the second password, apart from the fact that it makes it even more secure, is that it can be communicated to a second person.<br />
                                    This way the message can only be decrypted when both recipients are together to discover the message.<br />
                                    Without each other, it will be impossible to read the message.
                                </li>
                                <li><span className="important-block">Optionally, "To (email address)".</span> <br />
                                    We give you the possibility to send an email from our server directly to your recipient to inform him/her of the creation of a message intended for him/her. This will prevent you from being identified as the sender of the message.<br />
                                    You will be totally anonymous.
                                </li>
                                <li><span className="important-block">Optionally, "Hint"</span><br />
                                    Allows you to communicate a clue to your recipient.</li>
                                <li>Once finished, click on <span className="important-block">"Send Message"</span>.</li>
                            </ul>
                            <p>Only fields 1 and 2 are mandatory. But, in order to secure your message as much as possible, we recommend that you add at least a password.</p>
                            <p>Once your form has been sent, a unique identifier will be sent to you.
                                <br />This is the one that will have to be communicated to your recipient in order to access your message.</p>
                            <hr />
                            <h3>Can't send a message?</h3>
                            <p>When we cannot ensure the total confidentiality of your messages, you may get a message like<br />
                                "The Secure Message Sender server is temporarily unavailable because messages could be unsecure. Services will be back when you can send messages without fear".<br />
                                In this case, we recommend that you try again plausibly late. Your form will be automatically reset after a few seconds so that your message does not stay on your screen.
                            </p>
                            <hr />
                            <h3>How do I read a message?</h3>
                            <p>Go to the "Read Message" tab, enter your message ID and let it guide you.</p>
                            <hr />
                            <h3>Information:</h3>
                            <p>We also recommend you to avoid reading after people too curious to mount your window upwards to hide the form completely if there are people next to you.</p>
                            <p><span className="important-block">Caution: Secure Message Sender is in no way responsible for the messages that are sent and their content.</span></p>
                            <p>If you want to use the Secure Message Sender services in a high volume or in a professional context, you can use our APIs by contacting our administrator by clicking <a href="mailto:milad.ardehali@gmail.com">here</a>.</p>
                            <hr />
                   
                    </div>
                </div>
            </div>
        )
    }
}

export default SMSUserGuide;