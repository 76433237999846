import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import DocumentTitle from 'react-document-title';
import { 
    BrowserRouter as Router
   } from 'react-router-dom';

const SITE_TITLE = 'SecureMessage';

ReactDOM.render(
    <>
      <DocumentTitle title={ SITE_TITLE }>
        <Router>
            <App />
        </Router>
      </DocumentTitle>
    </>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
