import React from 'react';
import './App.css';
import SMSNavbar from './components/smsnavbar/SMSNavbar';
import { 
  Switch,
  Route
 } from 'react-router-dom';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
            <div className="App">
              <header className="App-header">
                <h1>Secure Message Sender {this.state.to} {this.state.hint}</h1>
                <div className="mouse_scroll">
                  <div className="mouse">
                    <div className="wheel"></div>
                  </div>
                  <div>
                    <span className="m_scroll_arrows unu"></span>
                    <span className="m_scroll_arrows doi"></span>
                    <span className="m_scroll_arrows trei"></span>
                  </div>
                </div>
              </header>
              <div className="mt2"></div>
              
                <div>
                  <Switch>
                    <Route path="/">
                      <SMSNavbar />
                    </Route>
                    <Route path="*">
                      <SMSNavbar />
                    </Route>
                  </Switch>
                </div>
              
              <div className="mtb2 footer">
                Secure Message Sender - v1 | For use API contact us | Contact: <a href="https://digi-lion.com/digi-lion/contact/" target="_blank" rel="noopener noreferrer">Click here</a>
                </div>
            </div>
    );
  }
}

export default App;

