import React from 'react';
import { MDBCol, MDBInput, MDBBtn, MDBCard, MDBCardBody } from "mdbreact";
import axios from 'axios';

class SMSRead extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            serverUrl: "https://api.digi-lion.com:5050/securemessagemanager/readmessage",
            password: null,
            secondPassword: null,
            messagekey: this.props.identificationKey !== null ? this.props.identificationKey : null,
            secureMessage: null,
            serverErrorMessage: null,
            showHint: false,
            showError: false,
            showForm: true,
            showMessage: false,
            hint: null
        }
    };

    passwordHandler = (event) => {
        this.setState({ password: event.target.value })
    }

    keyHandler = (event) => {
        this.setState({ messagekey: event.target.value })
    }

    secondPasswordHandler = (event) => {
        this.setState({ secondPassword: event.target.value })
    }

    handleSubmit = () => {
        this._buildMessageReadBody().then((data) => {
            this.getDataAxios(data);
        })
    }

    _buildMessageReadBody = async () => {
        return {
            identificationOfMessage: this.state.messagekey,
            password: this.state.password,
            secondPassword: this.state.secondPassword
        }
    }

    async getDataAxios(data) {
        await axios.post(
            this.state.serverUrl,
            data,
            { headers: { 'Content-Type': 'application/json' } }
        ).then(res => {
            if (res.data.messageAccess === false) {
                this.setState({ showHint: true });
                this.setState({ hint: res.data.response });
            } else if (res.data.messageAccess === true) {
                this.setState({ secureMessage: res.data.response });
                this.setState({ showMessage: true });
                this.setState({ showForm: false });
                this.setState({ showHint: false });
            }
        }).catch(error => {
            this.setState({ showForm: false });
            this.setState({ showError: true });
            if (error.response !== undefined) {
                this.setState({ serverErrorMessage: error.response.data });
            } else {
                this.setState({ serverErrorMessage: "The Secure Message Sender server is temporarily unavailable because messages could be unsecure. Services will be back when you can send messages without fear." });
            }
        }
        );
    }

    render() {
        return (
            <div>
                <div className="container" hidden={!this.state.showHint}>
                    <p>Information : </p>
                    <p>{this.state.hint}</p>
                </div>
                <div className="container" hidden={!this.state.showError}>
                    <p>Error message :</p>
                    <div className="errorMessage"><p>{this.state.serverErrorMessage}</p></div>
                </div>
                <div className="container" hidden={!this.state.showMessage}>
                    <p>Message :</p>
                    <p>{this.state.secureMessage}</p>
                </div>
                <div className="container" hidden={!this.state.showForm}>
                    <MDBCol md="12">
                        <MDBCard>
                            <MDBCardBody>
                                <div>
                                    <p className="h4 text-center py-4">Read message</p>
                                    <div className="grey-text">
                                        <MDBInput type="text" label="Message Key" icon="shield-alt" name={this.state.message} onChange={this.keyHandler} value= {this.state.messagekey}/>
                                        {this.state.messagekey !== null && this.state.messagekey !== '' ?
                                            <MDBInput type="password" label="Password (Optional)" icon="unlock-alt" name={this.state.message} onChange={this.passwordHandler} />
                                            : null}
                                        {this.state.password !== null && this.state.password !== '' ?
                                            <MDBInput type="password" label="Second Password (Optional)" icon="unlock-alt" name={this.state.message} onChange={this.secondPasswordHandler} />
                                            : null}
                                        <MDBBtn color="elegant" type="submit" onClick={this.handleSubmit}>Recover Secret Message</MDBBtn>
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </div>
            </div>
        )
    }
}

export default SMSRead;